<template>
  <section class="general">
    <div class="setting">
      <i class="icon fa-solid fa-language fa-xl"></i>
      <span class="title">Language</span>
      <l-select v-model="locale" :options="languages"></l-select>
    </div>
    <div class="setting timezone">
      <i class="icon fa-solid fa-clock fa-xl"></i>
      <span class="title">Timezone</span>
      <l-switch v-model="autoTimezone" off="Manual" on="Auto"></l-switch>
      <l-select v-model="timezone" grow :disabled="autoTimezone" :options="timezones"></l-select>
    </div>
  </section>
</template>

<script>
import moment from 'moment-timezone';
export default {
  data: () => ({
    languages: [
      {
        value: 'en',
        text: 'English'
      },
      {
        value: "cs",
        text: "Czech"
      }
    ],
    timezones: []
  }),
  computed: {
    locale: {
      get() {
        return this.$store.state.locale;
      },
      set(value) {
        this.$store.dispatch('setLocale', value);
      }
    },
    timezone: {
      get() {
        return this.$store.state.timezone;
      },
      set(value) {
        this.$store.commit('setTimezone', value);
      }
    },
    autoTimezone: {
      get() {
        return this.$store.state.autoTimezone;
      },
      set(value) {
        this.$store.commit('setAutoTimezone', value);
      }
    }
  },
  created() {
    this.timezones = moment.tz.names().map(name => ({
      value: name,
      text: name
    }));
  },
}
</script>

<style>

</style>